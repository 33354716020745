import { UrlSlugType } from "../../../../utils/enums/UrlSlug";
import { ReactComponent as HomeIcon } from "../../../../assets/icons/home.svg";
import { ReactComponent as OfficeIcon } from "../../../../assets/icons/office.svg";
import { ReactComponent as ToolsIcon } from "../../../../assets/icons/tools.svg";
import { ReactComponent as RolesIcon } from "../../../../assets/icons/roles.svg";
import { ReactComponent as SettingsIcon } from "../../../../assets/icons/settings.svg";
import { ReactNode, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material";

export type MenuItemsPropsType = {
  path: string;
  name: string;
  icon: ReactNode;
};

const MenuItems = () => {
  const { pathname } = useLocation();
  const {
    palette: {
      primary: { main },
      text: { secondary },
    },
  } = useTheme();

  const dynamicIconColorChange = useCallback(
    (currentPath: string) => {
      return {
        stroke: pathname.includes(currentPath) ? main : secondary,
      };
    },
    [pathname, main, secondary]
  );
  return [
    {
      path: UrlSlugType.DASHBOARD,
      name: "Dashboard",
      icon: <HomeIcon style={dynamicIconColorChange(UrlSlugType.DASHBOARD)} />,
    },
    {
      path: UrlSlugType.COMPANIES,
      name: "Companies",
      icon: (
        <OfficeIcon style={dynamicIconColorChange(UrlSlugType.COMPANIES)} />
      ),
    },
    {
      path: UrlSlugType.TOOLS,
      name: "Using Tools",
      icon: <ToolsIcon style={dynamicIconColorChange(UrlSlugType.TOOLS)} />,
    },
    {
      path: UrlSlugType.MANAGE_ROLES,
      name: "Manage Roles",
      icon: <RolesIcon style={dynamicIconColorChange(UrlSlugType.MANAGE_ROLES)} />,
    },
    {
      path: UrlSlugType.SETTINGS,
      name: "Settings",
      icon: (
        <SettingsIcon style={dynamicIconColorChange(UrlSlugType.SETTINGS)} />
      ),
    },
  ];
};

export default MenuItems;
