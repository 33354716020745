import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { ApiSlug } from "../utils/enums/ApiSlug";
import { logOut, setToken } from "../store/slices/auth.slice";

const query = fetchBaseQuery({
  baseUrl: "https://api.teambook.life/api/v1",
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as any).auth.token;

    
    token && headers.set("authorization", `Bearer ${token}`);

    return headers;
  },
});

const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args: any, api, extraOptions) => {
  let result = await query(args, api, extraOptions);

  if (result.meta?.response?.status === 200 && args.url === ApiSlug.LOGIN) {
    if ((result.data as any).data?.user?.role === "business_owner") {
      const newError = {
        status: 404,
        data: {
          status: false,
          code: 404,
          message: "You are not authorized to use this platform",
        },
      };
      return {
        meta: result.meta,
        error: newError,
      };
    }
  }

  if (result.error && result.error.status === 401) {
    // try to get a new token
    const refreshResult = await query(
      ApiSlug.REFERESH_TOKEN,
      api,
      extraOptions
    );
    if (refreshResult.data) {
      // store the new token
      api.dispatch(setToken((refreshResult.data as any).data?.token));
      // retry the initial query
      result = await query(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }

  if (result.error && result.error.status === 500) {
    api.dispatch(logOut());
  }

  return result;
};

export default baseQuery;
