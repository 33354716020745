import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { UrlSlugType } from "../utils/enums/UrlSlug";
import AuthLayout from "../layouts/AuthLayout";
import ResetPassword from "../pages/auth/ResetPassword";

// pages
const LoginPage = React.lazy(() => import("../pages/auth/LoginPage"));
const ForgotPassword = React.lazy(() => import("../pages/auth/ForgotPassword"));

const PublicRoutes = () =>
  useRoutes([
    {
      element: <AuthLayout />,
      children: [
        {
          path: UrlSlugType.LOGIN,
          element: <LoginPage />,
        },
        {
          path: UrlSlugType.FORGOT_PASSWORD,
          element: <ForgotPassword />,
        },
        {
          path: UrlSlugType.RESET_PASSWORD,
          element: <ResetPassword />,
        },
        {
          path: "*",
          element: <Navigate to={UrlSlugType.LOGIN} />,
        },
      ],
    },
  ]);

export default PublicRoutes;
