import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./api";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { ApiSlug } from "../utils/enums/ApiSlug";

export const authServiceApi = createApi({
  reducerPath: "authService",
  baseQuery: baseQuery,
  tagTypes: [],
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: ApiSlug.LOGIN,
        method: "POST",
        body: credentials,
      }),
    }),

    googleSSOLogin: builder.mutation({
      query: (search: string) => ({
        url: `${ApiSlug.SSO_GOOGLE}${search}`,
        method: "GET",
      }),
    }),

    forgotPassword: builder.mutation({
      query: (credentials) => ({
        url: ApiSlug.FORGOT_PASSWORD,
        method: "POST",
        body: credentials,
      }),
    }),

    resetPassword: builder.mutation({
      query: (credentials) => ({
        url: ApiSlug.RESET_PASSWORD,
        method: "POST",
        body: credentials,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useGoogleSSOLoginMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authServiceApi;
