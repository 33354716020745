import { useState, useCallback } from "react";
import { Box, Paper } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import SideBar from "./components/SideBar";

const PrivateLayout = () => {
  const [open, setOpen] = useState<boolean>(false);

  const handleSideDrawerOpen = useCallback(() => setOpen(true), []);
  const handleSideDrawerClose = useCallback(() => setOpen(false), []);

  return (
    <Box width="100vw" height="100vh" display="flex">
      <SideBar isOpen={open} handleClose={handleSideDrawerClose} />
      <Box width="100%">
        <Header handleMenuButtonClicked={handleSideDrawerOpen} />
        <Box
          width="100%"
          height="calc(100vh - 60px)"
          sx={{
            backgroundColor: {
              xs: (theme) => theme.palette.background.default,
              md: "#F5F6FA",
            },
          }}
          p={{ xs: 20, md: "30px 37px 20px 32px" }}
          overflow="auto"
        >
          <Paper
            sx={{
              minHeight: "100%",
              px: { xs: 15, md: 38 },
              pt: { xs: 15, md: 38 },
              pb: 15,
            }}
          >
            <Outlet />
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default PrivateLayout;
