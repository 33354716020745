import { Avatar, Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const ProfileCard = () => {
  const { first_name, last_name, email, avatar } = useSelector(
    (state: any) => state.auth.user ?? {}
  );
  return (
    <Box display="flex" gap={{ xs: 0, md: 7 }} alignItems="center">
      <Avatar
        sx={{ width: { xs: 28, md: 39 }, height: { xs: 28, md: 39 } }}
        src={avatar}
        imgProps={{ referrerPolicy: "no-referrer" }}
      />
      <Box display={{ xs: "none", md: "Block" }}>
        <Typography
          fontSize="12px"
          fontWeight={500}
          whiteSpace="nowrap"
          color="#5F616E"
        >
          {`${first_name} ${last_name}`}
        </Typography>
        <Typography
          fontSize="10px"
          fontWeight={300}
          whiteSpace="nowrap"
          color="#5F616E"
        >
          {email}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProfileCard;
