import { Fragment, useCallback } from "react";
import { Paper, Box, Drawer, useMediaQuery, useTheme } from "@mui/material";
import { ReactComponent as Logo } from "../../../../assets/icons/logo.svg";
import { ReactComponent as SignOutIcon } from "../../../../assets/icons/signout.svg";
import MenuItems, { MenuItemsPropsType } from "./MenuItems";
import { MenuItemButton } from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authServiceApi } from "../../../../services/authService";
import { logOut } from "../../../../store/slices/auth.slice";

type SideBarPropsType = {
  isOpen: boolean;
  handleClose: () => void;
};

const SideBar = ({ isOpen, handleClose }: SideBarPropsType) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSmallDevices = useMediaQuery(useTheme().breakpoints.down("md"));

  const isLocationActive = useCallback(
    (params: string) => pathname.includes(params),
    [pathname]
  );

  const handleLogOut = useCallback(() => {
    dispatch(logOut());
    dispatch(authServiceApi.util.resetApiState());
  }, [dispatch]);

  const content = (
    <Paper
      elevation={3}
      sx={{
        width: 215,
        minWidth: 215,
        height: "100vh",
        py: 16,
        display: "flex",
        flexFlow: "column",
        overflow: "auto",
      }}
    >
      <Box mx="auto" mb={{ xs: 40, md: 70 }} sx={{ cursor: "pointer" }}>
        <Logo style={{ width: "140px", height: "34px" }} />
      </Box>

      <Box mt={40} flexGrow={1}>
        {MenuItems().map((menu: MenuItemsPropsType) => (
          <MenuItemButton
            onClick={() => {
              navigate(menu.path);
              handleClose();
            }}
            key={menu.name}
            icon={menu.icon}
            name={menu.name}
            isActive={isLocationActive(menu.path)}
          />
        ))}
      </Box>
      <MenuItemButton
        mb={0}
        icon={<SignOutIcon />}
        onClick={handleLogOut}
        name="Sign Out"
        color="red"
        isError={true}
      />
    </Paper>
  );
  return (
    <Fragment>
      {isSmallDevices ? (
        <Drawer anchor="left" open={isOpen} onClose={handleClose}>
          {content}
        </Drawer>
      ) : (
        content
      )}
    </Fragment>
  );
};

export default SideBar;
