import {
  AppBar,
  Toolbar,
  Box,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// import SearchField from "../../../components/core/mui/SearchField";
import ProfileCard from "./common/ProfileCard";
import { ReactComponent as NotificationIcon } from "../../../assets/icons/notification.svg";
import { ReactComponent as MenuIcon } from "../../../assets/icons/menu.svg";
import { ReactComponent as MobileLogo } from "../../../assets/icons/logo-mobile.svg";

type HeaderPropsType = {
  handleMenuButtonClicked: () => void;
};

const Header = ({ handleMenuButtonClicked }: HeaderPropsType) => {
  const isSmallDevice = useMediaQuery(useTheme().breakpoints.down("md"));
  const { palette } = useTheme();

  return (
    <AppBar position="static" elevation={isSmallDevice ? 4 : 1}>
      <Toolbar
        disableGutters
        sx={{
          backgroundColor: palette.background.paper,
          px: { xs: 16, md: 75 },
          justifyContent: { xs: "space-between", md: "unset" },
        }}
      >
        {isSmallDevice && (
          <Box
            mr={20}
            sx={{ cursor: "pointer" }}
            onClick={handleMenuButtonClicked}
          >
            <MenuIcon />
          </Box>
        )}
        <Box display={{ md: "none" }}>
          <MobileLogo />
        </Box>
        <Box
          display={{ xs: "none", md: "block" }}
          maxWidth={500}
          width="100%"
          mx="auto"
        >
          {/* <SearchField /> */}
        </Box>
        <Box display="flex" alignItems="center" gap={24} ml={20}>
          {!isSmallDevice && (
            <>
              <NotificationIcon />
              <Box height={25}>
                <Divider
                  orientation="vertical"
                  sx={{
                    backgroundColor: palette.text.primary,
                  }}
                  variant="middle"
                />
              </Box>
            </>
          )}

          <ProfileCard />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
