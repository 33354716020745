import { useEffect } from "react";
import Container from "../../layouts/AuthLayout/components/common/AuthContainer";
import ResetPasswordForm from "../../components/forms/auth/ResetPasswordForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useResetPasswordMutation } from "../../services/authService";
import AuthSuccess from "../../layouts/AuthLayout/components/common/AuthSuccess";
import { UrlSlugType } from "../../utils/enums/UrlSlug";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [handleReset, { isSuccess, error }] = useResetPasswordMutation();

  useEffect(() => {
    !token && navigate(UrlSlugType.LOGIN);
  }, [token, navigate]);

  return (
    <Container>
      {isSuccess ? (
        <AuthSuccess handleButtonClicked={() => navigate(UrlSlugType.LOGIN)} />
      ) : (
        <ResetPasswordForm
          handleFormSubmit={({ password }) =>
            handleReset({ token, password, verification_type: "token" })
          }
          errorMessage={error?.data?.message}
        />
      )}
    </Container>
  );
};

export default ResetPassword;
