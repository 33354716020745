import { Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import TextField from "../../core/mui/TextField";

type ResetPasswordFormProps = {
  handleFormSubmit: (data: any) => void;
  errorMessage: string | null;
}

const ResetPasswordForm = ({ handleFormSubmit, errorMessage }: ResetPasswordFormProps) => {
  return (
    <form>
      <Typography mb={18} fontSize="26px" fontWeight="600" align="center">
        Create New Password
      </Typography>
      <Typography mb={30} variant="body1" color="text.secondary" align="center">
        To Create your new password, Please fill in the fields below
      </Typography>
      <TextField placeholder="New Password" fieldType="password" />
      <TextField placeholder="Retype Password" fieldType="password" />
      <LoadingButton fullWidth variant="contained">
        Create Password
      </LoadingButton>
      <LoadingButton
        fullWidth
        variant="text"
        sx={{ color: "text.primary" }}
        disableRipple
      >
        Cancel
      </LoadingButton>
    </form>
  );
};

export default ResetPasswordForm;
