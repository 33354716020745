import { useState } from "react";
import {
  Box,
  TextField as MuiTextField,
  TextFieldProps,
  styled,
} from "@mui/material";
import { InputAdornment, IconButton } from "@mui/material";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";

const StyledTextField = styled(MuiTextField)(({ theme }) => ({
  input: {
    height: "26px",
    padding: "10px 14px ",
    borderRadius: "10px",
    fontSize: "14px",
    "&::placeholder": {
      fontSize: "12px",
      fontWeight: "400",
      color: `${theme.palette.text.primary}60`,
      opacity: "1",
    },

    WebkitBoxShadow: "0 0 0 1000px white inset",
  },

  "& .MuiFormHelperText-root": {
    fontSize: "12px",
  },
}));

const TextField = ({
  height,
  icon,
  fieldType,
  ...textFieldProps
}: {
  height?: string;
  icon?: JSX.Element;
  fieldType?: string;
} & TextFieldProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event: any) => event.preventDefault();

  const changeAdornment = (fieldType?: string, icon?: JSX.Element) => {
    switch (fieldType) {
      case "password":
        return (
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? (
                <VisibilityOffOutlined sx={{ color: "rgba(24,29,51,0.24)" }} />
              ) : (
                <VisibilityOutlined sx={{ color: "rgba(24,29,51,0.24)" }} />
              )}
            </IconButton>
          </InputAdornment>
        );

      case "icon":
        return <InputAdornment position="end">{icon}</InputAdornment>;

      default:
        return null;
    }
  };

  return (
    <Box minHeight={height ? height : "75px"} width="100%">
      <StyledTextField
        type={
          fieldType === "password"
            ? showPassword
              ? "text"
              : "password"
            : "text"
        }
        fullWidth
        InputProps={{ endAdornment: changeAdornment(fieldType, icon) }}
        {...textFieldProps}
      />
    </Box>
  );
};

export default TextField;
