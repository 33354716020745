import { persistReducer, persistStore } from "redux-persist";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import localStorage from "redux-persist/lib/storage";
import authReducer from "./slices/auth.slice";
import { authServiceApi } from "../services/authService";
import { userServiceApi } from "../services/userService";
import { companyServiceApi } from "../services/companyService";
import { toolsServiceApi } from "../services/toolsService";
import { permissionServiceApi } from "../services/permissionService";

const rootConfig = {
  key: "teambook_admin_v1",
  storage: localStorage,
};

const persistedAuthReducer = persistReducer(rootConfig, authReducer);

export const store = configureStore({
  reducer: combineReducers({
    auth: persistedAuthReducer,
    [authServiceApi.reducerPath]: authServiceApi.reducer,
    [userServiceApi.reducerPath]: userServiceApi.reducer,
    [companyServiceApi.reducerPath]: companyServiceApi.reducer,
    [toolsServiceApi.reducerPath]: toolsServiceApi.reducer,
    [permissionServiceApi.reducerPath]: permissionServiceApi.reducer,
  }),
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      authServiceApi.middleware,
      userServiceApi.middleware,
      companyServiceApi.middleware,
      toolsServiceApi.middleware,
      permissionServiceApi.middleware,
    ] as any),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export const persistedStore = persistStore(store);
