import { ReactNode, CSSProperties } from "react";
import { Paper, PaperProps, Box } from "@mui/material";
import { ReactComponent as Logo } from "../../../../assets/icons/logo.svg";

const AuthContainer = ({
  children,
  sx,
  ...paperProps
}: { children: ReactNode; sx?: CSSProperties } & PaperProps) => {
  return (
    <Paper
      sx={{
        maxWidth: "380px",
        width: "100%",
        m: "auto",
        px: { xs: "16px", md: "32px" },
        py: { xs: "24px", md: "32px" },
        ...sx,
      }}
      {...paperProps}
    >
      <Box
        display={{ xs: "flex", md: "none" }}
        flexDirection="row"
        justifyContent="center"
        mb={30}
      >
        <Logo style={{ width: "167px", height: "40px" }} />
      </Box>
      {children}
    </Paper>
  );
};

export default AuthContainer;
