import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./api";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { ApiSlug } from "../utils/enums/ApiSlug";

export const permissionServiceApi = createApi({
  reducerPath: "permissionService",
  baseQuery: baseQuery,
  tagTypes: ["roles"],
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    getRoles: builder.query({
      query: ({ data }) => `${ApiSlug.GET_ROLES}?page_size=20&page=${data}&info=true`,
      providesTags: ["roles"]
    }),
    getPermissions: builder.query({
      query: () => ApiSlug.GET_PERMISSIONS,
    }),
    createRole: builder.mutation({
      query: (data) => ({
        url: ApiSlug.CREATE_ROLE,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["roles"]
    }),

  }),
});

export const {
  useLazyGetRolesQuery,
  useGetPermissionsQuery,
  useCreateRoleMutation
} = permissionServiceApi;
