import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./api";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { ApiSlug } from "../utils/enums/ApiSlug";

export const toolsServiceApi = createApi({
  reducerPath: "toolService",
  baseQuery: baseQuery,
  tagTypes: ["tools", "tool"],
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    getTools: builder.query({
      query: ({ data }) => `${ApiSlug.GET_TOOL}?page_size=100&page=${data}`,
      providesTags: ["tools"]
    }),

    getTool: builder.query({
      query: (id) => ApiSlug.GET_SINGLE_TOOL.replace("{id}", id),
      providesTags: ["tool"]
    }),

    createTool: builder.mutation({
      query: (data) => ({
        url: ApiSlug.CREATE_TOOL,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["tools"]
    }),

    updateTool: builder.mutation({
      query: ({ id, data }) => ({
        url: ApiSlug.UPDATE_TOOL.replace("{id}", id),
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["tools"]
    }),

    disableTool: builder.mutation({
      query: (id) => ({
        url: ApiSlug.DISABLE_TOOL.replace("{id}", id),
        method: "DELETE",
      }),
      invalidatesTags: ["tools"]
    }),

    restoreTool: builder.mutation({
      query: (id) => ({
        url: ApiSlug.RESTORE_TOOL.replace("{id}", id),
        method: "PUT",
      }),
      invalidatesTags: ["tools"]
    }),
  }),
});

export const {
  useLazyGetToolsQuery,
  useLazyGetToolQuery,
  useCreateToolMutation,
  useUpdateToolMutation,
  useDisableToolMutation,
  useRestoreToolMutation,
} = toolsServiceApi;
