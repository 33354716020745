import { Box } from "@mui/material";
import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";

const Splash = () => {
  return (
    <Box width="100%" height="100vh" display="flex">
      <Box m="auto">
        <Logo style={{ width: "250px", height: "60px" }} />
      </Box>
    </Box>
  );
};

export default Splash;
