import { createTheme, Shadows } from "@mui/material";
import { typography } from "./typography";

const theme = createTheme({
  palette: {
    primary: {
      main: "#007EE5",
      light: "#F5FBFF",
    },
    secondary: {
      main: "#9687E8",
      light: "#E3E0FA",
    },
    success: {
      main: "#06D6A0",
      light: "#E5FFF8",
    },
    error: {
      main: "#FF7777",
      light: "#FFF7F7",
    },
    info: { main: "#42D8DE", light: "#EDFEFF" },
    warning: { main: "#FEB558", light: "#FFF7ED" },
    background: {
      paper: "#FFFFFF",
      default: "#FAFAFA",
    },
    text: { primary: "#242A2E", secondary: "#767676" },
  },
  spacing: 1,
  shape: {
    borderRadius: 10,
  },

  shadows: [
    "none",
    "0px 3px 30px #00000008",
    "0px 2px 6px #0000000F",
    "6px 0px 20px #00000008",
    "10px 15px 60px rgba(0, 0, 0, 0.25)",
    ...Array(20).fill("none"),
  ] as Shadows,
  typography,
  mixins: { toolbar: { minHeight: 60 } },
  components: {
    MuiButton: {
      defaultProps: { disableElevation: true },
      styleOverrides: {
        root: {
          height: "47px",
          textTransform: "none",
          fontSize: "14px",
          fontWeight: "600",
        },
        text: {
          height: "unset",
          fontWeight: 500,
          ":hover": {
            backgroundColor: "transparent",
          },
        },
        outlined: {
          border: "1px solid #EBEBEB",
          color: "#7A86A1",
        },
      },
    },
  },
});

export default theme;
