import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./api";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { ApiSlug } from "../utils/enums/ApiSlug";

export const userServiceApi = createApi({
  reducerPath: "userService",
  baseQuery: baseQuery,
  tagTypes: [],
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    userStats: builder.query({
      query: () => ApiSlug.USER_STATS,
    }),

    getUsers: builder.query({
      query: ({ data }) =>
        `${ApiSlug.GET_USER}?page_size=20&company_id=${data.company_id}&page=${data.page}`,
    }),

    updateUser: builder.mutation({
      query: ({ id, data, avatar }) => ({
        url: `${ApiSlug.UPDATE_USER.replace("{id}", id)}${data?.current_password ? `?new_password=${data.new_password}&current_password=${data.current_password}`: ''}`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useLazyGetUsersQuery,
  useUpdateUserMutation,
  useUserStatsQuery,
} = userServiceApi;
