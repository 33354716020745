import { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { UrlSlugType } from "../utils/enums/UrlSlug";
import PrivateLayout from "../layouts/PrivateLayout/index";

const Dashboard = lazy(() => import("../pages/private/Dashboard"));
const Companies = lazy(() => import("../pages/private/Companies"));
const Company = lazy(() => import("../pages/private/Companies/Company"));
const Settings = lazy(() => import("../pages/private/Settings"));
const Tools = lazy(() => import("../pages/private/Tools"));
const ManageRoles = lazy(() => import("../pages/private/ManageRoles"));

const PrivateRoutes = () =>
  useRoutes([
    {
      element: <PrivateLayout />,
      children: [
        {
          path: UrlSlugType.DASHBOARD,
          element: <Dashboard />,
        },
        {
          path: UrlSlugType.COMPANIES,
          element: <Companies />,
        },
        { path: UrlSlugType.TOOLS, element: <Tools /> },
        { path: UrlSlugType.COMPANY, element: <Company /> },
        { path: UrlSlugType.MANAGE_ROLES, element: <ManageRoles /> },
        {
          path: UrlSlugType.SETTINGS,
          element: <Settings />,
        },
        {
          path: "*",
          element: <Navigate to={UrlSlugType.DASHBOARD} />,
        },
      ],
    },
  ]);

export default PrivateRoutes;
