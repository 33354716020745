import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./api";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { ApiSlug } from "../utils/enums/ApiSlug";

export const companyServiceApi = createApi({
  reducerPath: "companyService",
  baseQuery: baseQuery,
  tagTypes: ["companies", "CompanyOwner"],
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    getCompanies: builder.query({
      query: ({ data }) => `${ApiSlug.GET_COMPANY}?page_size=20&page=${data}&info=true`,
      providesTags: ["companies"]
    }),

    getCompanyInfo: builder.query({
      query: (id) => ApiSlug.GET_COMPANY_INFO.replace("{id}", id),
      providesTags: ["companies"]
    }),

    getCompanyOwnerInfo: builder.query({
      query: (id) => ApiSlug.GET_COMPANY_OWNER_INFO.replace("{id}", id),
      providesTags: ["CompanyOwner"]
    }),

    disableCompany: builder.mutation({
      query: (id) => ({
        url: ApiSlug.DISABLE_COMPANY.replace("{id}", id),
        method: "DELETE",
      }),
      invalidatesTags: ["companies"]
    }),

    enableCompany: builder.mutation({
      query: (id) => ({
        url: ApiSlug.ENABLE_COMPANY.replace("{id}", id),
        method: "PUT",
      }),
      invalidatesTags: ["companies"]
    }),
  }),
});

export const {
  useLazyGetCompaniesQuery,
  useLazyGetCompanyInfoQuery,
  useLazyGetCompanyOwnerInfoQuery,
  useDisableCompanyMutation,
  useEnableCompanyMutation
} = companyServiceApi;