import { BrowserRouter } from "react-router-dom";
import PublicRoutes from "./routes/PublicRoutes";
import PrivateRoutes from "./routes/PrivateRoutes";
import { useDispatch, useSelector } from "react-redux";
import { useLoginMutation } from "./services/authService";
import { useEffect } from "react";
import { setAuth } from "./store/slices/auth.slice";

function App() {
  const dispatch = useDispatch();
  const { data, isSuccess } = useLoginMutation({
    fixedCacheKey: "auth-login",
  })[1];
  const auth = useSelector((state: any) => state.auth);

  useEffect(() => {
    isSuccess && dispatch(setAuth(data?.data));
  }, [isSuccess, data, dispatch]);

  return (
    <BrowserRouter>
    {/* {<PrivateRoutes />} */}
      {auth?.token ? <PrivateRoutes /> : <PublicRoutes />}
    </BrowserRouter>
  );
}

export default App;
