export enum ApiSlug {
  // auth related
  SIGN_UP = "/auth/signup",
  LOGIN = "/auth/login",
  REFERESH_TOKEN = "/auth/refresh-token",
  FORGOT_PASSWORD = "/auth/forgot-password",
  RESET_PASSWORD = "/auth/password-reset",
  ACTIVATE_ACCOUNT = "/auth/activate",

  SSO_GOOGLE = "/auth/sso-auth-google",
  SSO_APPLE = "/auth/sso-auth-apple",

  // user related
  GET_USER = "/user",
  USER_STATS = "/user/stats/",
  UPDATE_USER = "/user/{id}",
  DISABLE_USER = "/user/{id}",
  ENABLE_USER = "/user/{id}",
  CHANGE_USER_PASS = "/user/{id}",

  // company related
  GET_COMPANY = "/company",
  GET_COMPANY_INFO = "/company/{id}",
  GET_COMPANY_OWNER_INFO = "/company/owner-info/{id}",
  DISABLE_COMPANY = "/company/{id}",
  ENABLE_COMPANY = "/company/restore/{id}",

  // tools related
  GET_TOOL = "/tool",
  CREATE_TOOL = "/tool",
  GET_SINGLE_TOOL = "/tool/{id}",
  UPDATE_TOOL = "/tool/{id}",
  DISABLE_TOOL = "/tool/{id}",
  RESTORE_TOOL = "/tool/restore/{id}",

  // permission related
  GET_ROLES = "/permission/roles",
  CREATE_ROLE = "/permission/roles",
  GET_PERMISSIONS = "/permission",
  ASSIGN_PERMISSION = "/permission/role/{id}"
}
