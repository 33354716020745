import { Outlet } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as TeamIcon } from "../../assets/icons/front-cover.svg";

const AuthLayout = () => {
  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      flexDirection="row"
      px="clamp(20px, 5vw, 70px)"
      pb={{ xs: "16px", sm: "30px" }}
      sx={{ backgroundColor: { md: "background.paper" } }}
      overflow="auto"
    >
      <Box
        width="60vw"
        height="100%"
        display={{ xs: "none", md: "flex" }}
        pt={{ xs: "16px", md: "102px" }}
        px="40px"
        flexDirection="column"
        alignItems="center"
      >
        <Box width="100%" mb={20} flexBasis="100px">
          <Logo style={{ width: "200px", height: "47px" }} />
        </Box>

        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box width="100%" display="flex">
            <TeamIcon
              style={{
                margin: "auto",
                maxWidth: "541.71px",
                width: "100%",
                // maxHeight: "392.83px",
              }}
            />
          </Box>

          <Typography
            mt={24}
            fontSize="28px"
            fontWeight={600}
            color="primary"
            align="center"
          >
            Manage your business easier
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{ maxWidth: "420px", mx: "auto" }}
            color="#24242450"
          >
            Swiftly manage, monitor and keep track of all Team book user’s
            activities more efficiently at ease.
          </Typography>
        </Box>
      </Box>

      <Box width={{ xs: "100%", md: "50%" }} height="100%" display="flex">
        <Outlet />
      </Box>
    </Box>
  );
};

export default AuthLayout;
