export enum UrlSlugType {
  LOGIN = "/login",
  RESET_PASSWORD = "/reset-password",
  VERIFY_EMAIL = "/verifymail",
  FORGOT_PASSWORD = "/forgot-password",
  DASHBOARD = "/dashboard",
  COMPANIES = "/companies",
  COMPANY = "/companies/:id",
  SETTINGS = "/settings",
  TOOLS = "/tools",
  MANAGE_ROLES = "/roles",
}
